<template>
  <div class="page-fixed-footer" :class="{collapse: sidebar}">
    <slot>
      <el-button v-if="!hideCancel" class="btn" plain @click="$emit('cancel')">{{ cacnelBtn }}</el-button>
      <el-button
        v-loading="confirmLoading"
        :disabled="disabledConfirm"
        class="btn"
        type="primary"
        @click="$emit('confirm')"
      >
        {{ confirmBtn }}
      </el-button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'PageFixedFooter',
  props: {
    disabledConfirm: { type: Boolean, default: false },
    disabledCancel: { type: Boolean, default: false },
    hideCancel: { type: Boolean, default: false },
    confirmLoading: { type: Boolean, default: false },
    cacnelBtn: { type: String, default: '取消' },
    confirmBtn: { type: String, default: '確認' },
  },
  computed: {
    sidebar () {
      return this.$store.getters.sidebar
    },

    isMobile () {
      return this.$store.state.app.isMobile
    },
  },
}
</script>

<style lang="postcss" scoped>
.page-fixed-footer {
  @apply fixed bottom-0 bg-white left-0 right-0 z-[5];
  @apply flex justify-end py-[20px] px-[40px] rounded-tr-sm rounded-tl-sm;
  box-shadow: 0px -2px 6px rgba(95, 95, 95, 0.1);
  transition: ease-in-out .3s;
}

.btn {
  @apply min-w-[100px];
}

.collapse {
  @apply left-[260px];
}
</style>
