import { EmailLogin, Logout } from '@/api/auth'
import router, { resetRouter } from '@/router'

import ls from '@/utils/localstorage'
import { useNewsHint } from '@/use/useNewsHint'
import { gEvent, setUserProperties, userPropertiesConfig } from '@/use/useGtag'

const state = {
  token: null,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    ls.setToken(token)
    state.token = token
  },
}

const actions = {
  async login ({ commit }, { orgCode, email, password, agreeTerms }) {
    const { token } = await EmailLogin({ orgCode, email, password, agreeTerms })
    if (token) {
      commit('SET_TOKEN', token)
      const { checkUnreadArticles } = useNewsHint()
      checkUnreadArticles()
    }
    return token
  },
  async quickLogin ({ commit }, { token }) {
    if (token) commit('SET_TOKEN', token)
  },

  async changeShop ({ dispatch }, { shopId, message }) {
    setUserProperties(userPropertiesConfig.shopId, shopId)
    gEvent('change_shop', {
      new_shopId: shopId,
    })

    const shopData = await dispatch('shop/findShop', { shopId }, { root: true })
    if (shopData) {
      setUserProperties(userPropertiesConfig.shopName, shopData.name)
    }
    const { features, shopPlan } = await dispatch(
      'user/getFeature',
      { shopId, message },
      { root: true },
    )
    if (!shopPlan) return false
    resetRouter()
    const accessRoutes = await dispatch(
      'permission/generateRoutes',
      { features, shopPlan },
      { root: true },
    )
    accessRoutes.map((item) => router.addRoute(item))
    return true
  },
  async logout ({ commit }) {
    resetRouter()
    await Logout()
    ls.removeNeedTransfer()
    commit('user/SET_ROLES', '', { root: true })
    commit('SET_TOKEN', null)
    commit('user/SET_USER', null, { root: true })
    commit('user/SET_NOW_SHOP', null, { root: true })
    commit('app/SET_SIDEBAR_PAGE', 1, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
