const config = {
  backendHost: '$VUE_APP_BACKEND_HOST',
  frontendHost: '$VUE_APP_FRONTEND_HOST',
  imgServerHost: '$VUE_APP_IMG_SERVER_HOST',
  bobAnalyticsHost: '$VUE_APP_BOB_ANALYTICS_HOST',
  featureKeyDebugger: '$VUE_APP_FEATURE_KEY_DEBUGGER',
  // title: '美業歐巴後台管理',
  // appVersion: '$VUE_APP_VERSION',
  appVersion: '$VUE_APP_TAG_VERSION',
  mainDashboard: '$VUE_APP_MAIN_DASHBOARD',
  ecommerceHost: '$VUE_APP_ECOMMERCE_HOST',
  ecommerceModule: '$VUE_APP_ECOMMERCE_MODULE',
  ecommerceCVSMode: '$VUE_APP_ECOMMERCE_CVS_MODE',
  appTheme: '$VUE_APP_THEME',
  gtmId: '$VUE_APP_GTM_ID',
  e2eApiKey: 'VUE_APP_E2E_API_KEY',
  appRuntime: '$VUE_APP_RUNTIME',
  pubAptHost: '$VUE_APP_PUB_APT_HOST',
  // 自訂名稱: ’$ + .env變數名稱'
}

const env = process.env.NODE_ENV

// 代入與替換
export default (name) => {
  if (!(name in config)) {
    return
  }

  const value = config[name]
  if (!value) {
    return
  }

  // 搜尋前綴為 $VUE_APP_ 開頭的Value 並代入替換 .env 對應的值
  if (value.startsWith('$VUE_APP_')) {
    const envName = value.substr(1)
    let envValue = process.env[envName]
    if (envName === 'VUE_APP_BACKEND_HOST') {
      if (env === 'e2e') envValue = process.env.VUE_APP_E2E_BACKEND_HOST
    }
    // const envValue = process.env[envName]
    if (envValue) {
      return envValue
    }
  } else {
    return value
  }
}
