import Layout from '@/layout'

export const aiAnalysisRoutes = [
  {
    path: '/ai-analysis',
    component: Layout,
    name: 'AIAnalysis',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: 'AI 智能模組',
    },
    children: [
      // AI 熱門預約時段預測
      {
        path: 'popular-booking-time-prediction',
        name: 'PopularBookingTimePrediction',
        component: () => import('@/views/PopularBookingTimePrediction/PopularBookingTimePrediction.vue'),
        meta: {
          title: 'AI 熱門預約時段預測',
          action: 'admin.beautyAIAptSchedulePrediction.page',
        },
      },
      {
        path: 'appointment-unit-analysis',
        name: 'BeautyAIAppointmentUnitAnalysis',
        component: () => import('@/views/BeautyAIAppointmentUnitAnalysis/BeautyAIAppointmentUnitAnalysis.vue'),
        meta: {
          title: 'AI 服務人員績效',
          action: 'admin.beautyAIAptUnitAnalysis.page',
        },
      },
      {
        path: 'appointment-unit-analysis/detail/:id',
        name: 'BeautyAIAppointmentUnitAnalysisDetail',
        hidden: true,
        component: () => import('@/views/BeautyAIAppointmentUnitAnalysisDetail/BeautyAIAppointmentUnitAnalysisDetail.vue'),
        meta: {
          title: 'AI 服務人員績效詳情',
          action: 'admin.beautyAIAptUnitAnalysis.page',
        },
      },

      // AI 銷售統計圖表 - SalesProduct
      {
        path: 'sales-product',
        name: 'BeautyAISalesProductAnalysis',
        component: () => import('@/views/BeautyAISalesProductAnalysis/BeautyAISalesProductAnalysis.vue'),
        meta: {
          title: 'AI 銷售統計圖表',
          action: 'admin.beautyAISalesProductAnalysis.page',
        },
      },

      // 行銷策略產生器 - EventTemplateGenerator
      {
        path: 'event-template-generator',
        name: 'BeautyAIEventTemplateGenerator',
        component: () => import('@/views/BeautyAIEventTemplateGenerator/BeautyAIEventTemplateGenerator.vue'),
        meta: {
          title: 'AI 行銷策略產生器',
          action: 'admin.beautyAISalesProductAnalysis.page',
        },
      },
    ],
  },
]
