import { fetchAll } from '@/use/useFetchAll'
import { GetTags, CreateTags, UpdateTags, DeleteTags } from '@/api/memberTags'
import { mapSystem2UI } from '@/config/member'
const state = () => ({
  tags: [],
  isDone: false,
  shopId: '',
  timestamp: null,
})
const THROTTLE_TIME = 30 * 60 * 1000

const getters = {
  tags: (state) => {
    return state.tags.map(i => {
      return {
        ...i,
        isNullMember: i.memberCount === 0,
        uiGroup: i.isSystem ? mapSystem2UI[i.systemGroup] || '_other' : '_custom',
      }
    })
  },
}

const actions = {
  async getAllTags ({ getters, state }, payload) {
    if (state.isDone && state.shopId === payload.shopId) {
      const diff = +new Date() - state.timestamp
      if (diff < THROTTLE_TIME) return Promise.resolve([getters.tags, null])
    }
    const [res, err] = await fetchAll(GetTags, { ...payload })
    if (err) {
      return [null, err]
    }
    state.tags = res
    state.isDone = true
    state.shopId = payload.shopId
    state.timestamp = new Date()

    return [getters.tags, null]
  },
  async createTag (context, payload) {
    const isDuplicated = await context.dispatch('checkConflictTagName', payload.name)
    if (isDuplicated) {
      return [null, '標籤名稱已存在!']
    }
    const [res, err] = await CreateTags(payload)
    if (err) return [null, err]
    context.state.isDone = false
    context.dispatch('getAllTags', { shopId: payload.shopId })
    return [res, null]
  },
  async updateTag (context, payload) {
    const isDuplicated = await context.dispatch('checkConflictTagName', payload.name)
    if (isDuplicated) {
      return [null, '標籤名稱已存在!']
    }
    const [res, err] = await UpdateTags(payload)
      .then(res => [res, null])
      .catch(err => [null, err])
    if (err) return [null, err]
    context.state.isDone = false
    context.dispatch('getAllTags', { shopId: payload.shopId })
    return [res, null]
  },
  async deleteTag (context, payload) {
    const [res, err] = await DeleteTags(payload)
      .then(res => [res, null])
      .catch(err => [null, err])
    if (err) return [null, err]
    context.state.isDone = false
    context.dispatch('getAllTags', { shopId: payload.shopId })
    return [res, null]
  },
  checkConflictTagName ({ state }, name) {
    return !!state.tags.find(i => i.name === name)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
}
