import { set, get } from 'lodash'
import configuration from '@/configuration'

const gtmId = configuration('gtmId')

const state = {
  config: false,
}

export const userPropertiesConfig = {
  userId: 'userId',
  userEmail: 'userEmail',
  userName: 'userName',
  shopId: 'shopId',
  shopName: 'shopName',
  orgId: 'orgId',
  orgName: 'orgName',
  roleType: 'roleType',
}

export const userProperties = {
  userId: '',
  userEmail: '',
  userName: '',
  shopId: '',
  shopName: '',
  orgId: '',
  orgName: '',
  roleType: '',
}

export const setUserProperties = (key, value) => {
  if (!state.config) {
    window.gtag('config', gtmId, { ...userProperties })
    state.config = true
  }
  if (get(userProperties, key)) return
  set(userProperties, key, value)
  window.gtag('set', 'user_properties', userProperties)
}

export const gEvent = (event, data) => {
  window.dataLayer.push({
    event,
    ...data,
  })
}
