import Layout from '@/layout'

export const memberShopRoutes = [
  {
    path: '/member-shop',
    component: Layout,
    name: 'Coupon',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '會員商城模組',
    },
    children: [
      {
        path: 'product-settings',
        name: 'MemberShopProductSettings',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopProductSettings/MemberShopProductSettings.vue'
          ),
        meta: {
          title: '商城商品設定',
          action: 'adminView.memberStoreProduct.page',
        },
      },
      {
        path: 'product-edit/:id?',
        name: 'MemberShopProductEdit',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopProductEdit/MemberShopProductEdit.vue'
          ),
        hidden: true,
        meta: {
          action: 'adminView.memberStoreProduct.page',
        },
      },
      {
        path: 'order-manage',
        name: 'MemberShopOrderManage',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopOrderManage/MemberShopOrderManage.vue'
          ),
        meta: {
          title: '商城訂單管理',
          action: 'adminView.memberStoreOrder.page',
        },
      },
      {
        path: 'order-detail/:id',
        name: 'MemberShopOrderDetail',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopOrderDetail/MemberShopOrderDetail.vue'
          ),
        hidden: true,
        meta: {
          title: '商城訂單詳情',
          action: 'adminView.memberStoreOrder.page',
        },
      },
      {
        path: 'giveaway',
        name: 'MemberShopGiveaway',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopGiveaway/MemberShopGiveaway.vue'
          ),
        meta: {
          title: '商城贈品活動',
          action: 'admin.memberStorePromotion.page',
        },
      },
      {
        path: 'giveaway/edit/:id?',
        name: 'MemberShopGiveawayEdit',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopGiveawayEdit/MemberShopGiveawayEdit.vue'
          ),
        hidden: true,
        meta: {
          title: '商城贈品活動編輯',
          action: 'admin.memberStorePromotion.page',
        },
      },
      {
        path: 'point-feedback-activity',
        name: 'MemberShopFeedbackActivity',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopFeedbackActivity/MemberShopFeedbackActivity.vue'
          ),
        meta: {
          title: '商城回饋活動',
          action: 'admin.memberStoreRewardActivity.page',
        },
      },
      {
        path: 'point-feedback-activity/:id?',
        name: 'MemberShopFeedbackActivityEdit',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopFeedbackActivityEdit/MemberShopFeedbackActivityEdit.vue'
          ),
        hidden: true,
        meta: {
          action: 'admin.memberStoreRewardActivity.page',
        },
      },
    ],
  },
]
