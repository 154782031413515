<template>
  <div class="shop-feature-selector">
    <ElInputWrapper>
      <div class="flex items-center" style="gap: 5px">
        <el-input v-model="control.key" clearable placeholder="featureKey" style="width: 300px" />
        <el-select v-model="control.value" style="width: 80px">
          <el-option
            v-for="option in [{value: true, label: '開啟'}, {value:false, label: '關閉'}]"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
        <div>
          <el-button style="height: 36px; padding: 5px 10px" plain @click="onUpdateFeatureKey">新增</el-button>
          <el-button style="height: 36px; padding: 5px 10px" type="danger" @click="onRemoveFeatureKey">移除</el-button>
        </div>
      </div>
    </ElInputWrapper>
    <div class="overflow-y-auto" style="height: 500px">
      <el-checkbox-group v-model="enabledFeatures" class="flex flex-col" @change="onChange">
        <el-checkbox
          v-for="(value, key) in displayFeatures"
          :key="key"
          :label="key"
        />
      </el-checkbox-group>
    </div>
    <el-button block plain @click="reset">重置</el-button>
  </div>
</template>

<script>
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { defineComponent, ref, onMounted, computed, reactive } from 'vue'
import store from '@/store'
import { omitBy, filter, pick, lowerCase } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ShopFeatureSelector',
  components: { ElInputWrapper },
  props: {
    search: String,
    testRoute: Boolean,
    useOriginData: Boolean,
  },
  setup (props) {
    const { originShopPlanFeatures, computedShopPlanFeatures, refreshRoutes } = usePermissions()

    const enabledFeatures = ref([])
    const control = reactive({
      key: null,
      value: null,
    })

    const dataSource = computed(() => {
      if (props.useOriginData) return originShopPlanFeatures.value
      return computedShopPlanFeatures.value
    })

    const commitKey = computed(() => {
      if (props.useOriginData) return 'permission/SET_RAW_PERMISSIONS'
      return 'permission/SET_COMPUTED_PERMISSIONS'
    })

    const reset = () => {
      // store.commit('permission/SET_COMPUTED_PERMISSIONS', {
      //   key: 'shopPlanFeature', data: { ...originShopPlanFeatures.value },
      // })
      // const enabled = omitBy(originShopPlanFeatures.value, feature => !feature)
      // enabledFeatures.value = Object.keys(enabled)

      window.location.reload()
    }

    const displayFeatures = computed(() => {
      if (!props.search) return dataSource.value
      const searchKey = lowerCase(props.search)
      const features = Object.keys(dataSource.value)
      const matchs = filter(features, (feature) => lowerCase(feature).includes(searchKey))
      return pick(dataSource.value, matchs)
    })

    const onChange = async (newValue) => {
      const features = dataSource.value
      for (const key in features) {
        if (newValue.includes(key)) features[key] = true
        else features[key] = false
      }
      store.commit(commitKey.value, {
        key: 'shopPlanFeatures', data: features,
      })
      if (props.testRoute) {
        console.log('here')
        await refreshRoutes(props.useOriginData)
      }
    }

    const onUpdateFeatureKey = async () => {
      if (!control.key) return
      const cloneShopFeatures = { ...dataSource.value }
      cloneShopFeatures[control.key] = control.value
      store.commit(commitKey.value, { key: 'shopPlanFeatures', data: cloneShopFeatures })
      refresh()
      if (props.testRoute) await refreshRoutes(props.useOriginData)
    }
    const onRemoveFeatureKey = async () => {
      if (!control.key) return
      const cloneShopFeatures = { ...dataSource.value }
      delete cloneShopFeatures[control.key]
      store.commit(commitKey.value, { key: 'shopPlanFeatures', data: cloneShopFeatures })
      if (props.testRoute) {
        console.log('here')
        if (props.testRoute) await refreshRoutes(props.useOriginData)
      }
    }

    const refresh = () => {
      const enabled = omitBy(dataSource.value, feature => !feature)
      enabledFeatures.value = Object.keys(enabled)
    }

    onMounted(() => {
      const enabled = omitBy(originShopPlanFeatures.value, feature => !feature)
      enabledFeatures.value = Object.keys(enabled)
      console.log('rewfwe')
    })
    return {
      displayFeatures,
      enabledFeatures,
      onChange,
      reset,
      computedShopPlanFeatures,
      refresh,
      control,
      onUpdateFeatureKey,
      onRemoveFeatureKey,
      dataSource,
    }
  },
})
</script>

<style lang="postcss" scoped>
.shop-feature-selector {
  @apply h-full flex flex-col gap-[12px] pt-[12px];
}

::v-deep .el-checkbox__label {
  @apply text-gray-40;
}

::v-deep .el-checkbox.is-checked .el-checkbox__label {
  @apply text-[#58f993];
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  @apply bg-[#58f993];
}

::v-deep .el-tabs__item.is-active {
  @apply text-[#58f993];
}
</style>
