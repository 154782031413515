import Layout from '@/layout'

export const pointRoutes = [
  {
    path: '/point',
    component: Layout,
    name: 'Point',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '點數模組',
    },
    children: [
      {
        path: '/point-report',
        name: 'PointReport',
        component: () => import('@/views/Point/PointReport/PointReport.vue'),
        meta: {
          title: '點數報表',
          action: 'admin.shopPoint.page',
        },
      },
      {
        path: 'point-exchange-center',
        name: 'MemberShopPointExchangeCenter',
        component: () => import('@/views/MemberShop/MemberShopPointExchangeCenter/MemberShopPointExchangeCenter.vue'),
        meta: {
          title: '點數兌換中心',
          action: 'adminView.memberStorePointExchangeProduct.page',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_COUPON_LIST',
        },
      },
      {
        path: 'point-exchange-record',
        name: 'MemberShopPointExchangeRecord',
        component: () => import('@/views/MemberShop/MemberShopPointExchangeRecord/MemberShopPointExchangeRecord.vue'),
        meta: {
          title: '點數兌換紀錄',
          action: 'adminView.memberStorePointExchangeOrder.page',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_COUPON_LIST',
        },
      },
      {
        path: 'point-exchange-record/detail/:id',
        name: 'MemberShopPointExchangeRecordDetail',
        component: () => import('@/views/MemberShop/MemberShopPointExchangeRecordDetail/MemberShopPointExchangeRecordDetail.vue'),
        hidden: true,
        meta: {
          title: '點數兌換紀錄詳情',
          action: 'admin.shopPoint.singleFind',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_COUPON_LIST',
        },
      },
      {
        path: 'product-exchange-edit/:id?',
        name: 'MemberShopProductExchangeEdit',
        component: () =>
          import(
            '@/views/MemberShop/MemberShopPointExchangeProductEdit/MemberShopPointExchangeProdctEdit.vue'
          ),
        hidden: true,
        meta: {
          action: 'adminView.memberStorePointExchangeProduct.page',
        },
      },
      {
        path: 'point-link',
        name: 'MemberShopPointLink',
        component: () => import('@/views/MemberShop/MemberShopPointLink/MemberShopPointLink.vue'),
        meta: {
          title: '點數連結管理',
          action: 'admin.shopPointLink.page',
        },
      },
      {
        path: 'point-link-edit/:id?',
        name: 'MemberShopPointLinkEdit',
        component: () => import('@/views/MemberShop/MemberShopPointLinkEdit/MemberShopPointLinkEdit.vue'),
        hidden: true,
        meta: {
          title: '新增點數連結',
          action: 'admin.shopPointLink.page',
        },
      },
      {
        path: 'point-link/detail/:id',
        name: 'MemberShopPointLinkDetail',
        component: () => import('@/views/MemberShop/MemberShopPointLinkDetail/MemberShopPointLinkDetail.vue'),
        hidden: true,
        meta: {
          title: '點數連結管理列表',
          action: 'admin.shopPointLinkCode.page',
        },
      },
    ],
  },
]
