import Vue from 'vue'
import VueRouter from 'vue-router'
import homePageRoutes from './homePage'
import marketingRoutes from './marketing'
import { appointmentRoutes } from './appointment'
import { classTicketRoutes } from './classTicket'
import { ecommerceRoutes } from './ecommerce'
import { salesRoutes } from './sales'
import { marketLeagueRoutes } from './marketing-league'
import { lotteryRoutes } from './lottery'
import { couponRoutes } from './coupon'
import { walletRoutes } from './wallet'
import { rebateRoutes } from './rebate'
import { parametersRoutes } from './parameters/index.js'
import { companyContactMenuRoutes } from './companyMenu'
import { pointRoutes } from './point'
import { memberShopRoutes } from './memberShop'
import { externalTransactionRoutes } from './externalTransaction'
import { rewardActivityRoutes } from './rewardActivity'
import { operateDashboardRoutes } from './operate'
import { memberCenterRoutes } from './memberCenter'
import { rewardPostponeRoutes } from './rewardPospone'
import { progressNotificationRoutes } from './progressNotification'
import { pubAptRoutes } from './pubApt'
import { otherRoutes } from './other'
import { subscribeRoutes } from './subscribe'
import { batch } from '@/queue/bobAction'
import { resourceRoutes } from './resource'
// import { monetaryBonusRoutes } from './monetaryBonus' 火箭紅心
import { aiAnalysisRoutes } from './aiAnalysis'
import { sheetExportCenterRoutes } from './sheetExportCenter'
import memberRoutes from './member'
import testRoutes from './test'
import store from '@/store'
import { get, includes } from 'lodash'
import { keepAliveConfig } from '@/config/keepAlive'
/* Layout */
import Layout from '@/layout/index.vue'
import dayjs from '@/lib/dayjs'

Vue.use(VueRouter)

/**
 * constantRoutes
 * 基本的頁面
 */
export const constantRoutes = [
  ...testRoutes,
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/test/Test.vue'),
    hidden: true,
  },
  {
    path: '/login/:orgCode?',
    name: 'Login',
    component: () => import('@/views/Login/Login'),
    hidden: true,
  },
  {
    path: '/auth-login',
    name: 'AuthLogin',
    component: () => import('@/views/Login/AuthLogin'),
    hidden: true,
  },
  {
    path: '/read-member-history-file/:shopId/:id/:downloadKey',
    name: 'Read Member History File',
    component: () => import('@/views/Members/ReadMemberHistoryFile'),
    hidden: true,
    props: true,
  },
  {
    path: '/dashboard',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Home'),
        name: 'Dashboard',
        hidden: true,
        meta: {
          title: '控制台',
          action: 'admin.*',
        },
      },
      {
        path: '/daily-view',
        name: 'DailyView',
        component: () => import('@/views/DailyView/DailyView'),
        meta: {
          title: '當日預約行事曆',
          // action: 'admin.lineConfig.page',
        },
      },
      ...homePageRoutes,
      // {
      //   path: '/homepage',
      //   name: 'HomePage',
      //   component: () => import('@/views/HomePage/HomePage'),
      //   hidden: true,
      //   meta: {
      //     // action: 'admin.lineConfig.page',
      //   },
      // },
      {
        path: '/parameter-settings',
        name: 'ParameterSettings',
        component: () => import('@/views/Parameters/Parameters'),
        hidden: true,
        meta: {
          action: 'admin.lineConfig.page',
        },
      },
    ],
  },
  {
    path: '/account-config',
    component: Layout,
    children: [
      {
        path: '',
        name: 'AccountConfig',
        hidden: true,
        component: () => import('@/views/Basic/AccountConfig'),
        meta: {
          title: '帳號設定',
          disabledQrcodeReader: true,
        },
      },
    ],
  },
  // -- Not Found 404
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import('@/views/NotFound/NotFound'),
    hidden: true,
  },
]

/**
 * orgSettingsRoutes
 * 主要給集團使用的基本頁面
 */
export const orgSettingsRoutes = [
  {
    path: '/branch-shop-options',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Basic/BranchShopOptions'),
        name: 'BranchShopOptions',
        meta: {
          title: '分店管理',
          disabledQrcodeReader: true,
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_OPTIONS',
          scopeType: 'OrgId',
        },
      },
    ],
  },
  {
    path: '/oauth-setting',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Basic/OAuthSetting/OAuthSetting.vue'),
        name: 'OAuthSetting',
        meta: {
          title: '組織登入設定',
          action: 'admin.authServiceConfig.page',
          disabledQrcodeReader: true,
          bobPageAction: 'PAGE_VIEW_DASH_AUTH_SETTING',
          scopeType: 'OrgId',
        },
      },
      {
        path: 'edit/policy',
        hidden: true,
        component: () => import('@/views/Basic/OAuthSetting/EditPolicySetting/EditPolicySetting.vue'),
        name: 'EditPolicySetting',
        meta: {
          title: '組織登入設定',
          action: 'admin.authServiceConfig.page',
          disabledQrcodeReader: true,
        },
      },
    ],
  },

  {
    path: '/branch-shop-setting',
    component: Layout,
    children: [
      {
        path: '',
        name: 'BranchShopSetting',
        component: () => import('@/views/Basic/BranchShopSetting'),
        meta: {
          title: '分店設定',
          action: 'admin.shop.page',
          disabledQrcodeReader: true,
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_LIST',
          scopeType: 'OrgId',
        },
      },
    ],
  },
  {
    path: '/admin-list',
    component: Layout,
    children: [
      {
        path: '',
        name: 'AdminList',
        component: () => import('@/views/Basic/AdminList'),
        meta: {
          title: '管理員設定',
          action: 'admin.user.page',
          disabledQrcodeReader: true,
          bobPageAction: 'PAGE_VIEW_DASH_ADMIN_LIST',
          scopeType: 'OrgId',
        },
      },
    ],
  },
  {
    path: '/all-shop-external-transaction-record-list',
    component: Layout,
    children: [
      {
        path: '',
        name: 'AllExternalTransactionRecordList',
        component: () => import('@/views/AllExternalTransactionRecordList/AllExternalTransactionRecordList.vue'),
        meta: {
          title: '各分店消費總表',
          action: 'admin.user.page',
          disabledQrcodeReader: true,
        },
      },
    ],
  },
  {
    path: '/contract-setting',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Basic/ContractSetting'),
        name: 'ContractSetting',
        meta: {
          title: '合約方案列表',
          action: 'admin.contractPlan.page',
          disabledQrcodeReader: true,
          bobPageAction: 'PAGE_VIEW_DASH_CONTRACT_LIST',
          scopeType: 'OrgId',
        },
      },
    ],
  },
]

const basicRoutes = [
  // 基本模組
  {
    path: '/basic',
    component: Layout,
    alwaysShow: true,
    isGroup: true,
    name: 'Basic',
    meta: {
      title: '基本模組',
    },
    children: [
      {
        path: '/shop-dashboard',
        component: () => import('@/views/Basic/Dashboard'),
        name: 'ShopDashboard',
        meta: {
          title: '店家數據分析',
          action: 'admin.shopAnalysis.dataPage',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_BASIC_DASHBOARD',
        },
      },
      {
        path: '/action-log-dashboard',
        component: () => import('@/views/Basic/ActionRecordDashboard'),
        name: 'ActionLogDashboard',
        meta: {
          title: '操作紀錄數據分析',
          action: 'admin.shopAnalysis.actionPage',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_BASIC_DASHBOARD',
        },
      },
    ],
  },
  // 會員模組
  ...memberRoutes,
  // 行銷模組
  marketingRoutes,
]

/**
 * asyncRoutes
 * 可以根據不同使用者顯示不同的頁面
 */
export const asyncRoutes = () => {
  return [
    {
      path: '/homepage',
      name: 'HomePage',
      component: () => import('@/views/HomePage/HomePage'),
      meta: {
        title: '店家首頁',
        // action: 'admin.lineConfig.page',
      },
    },
    {
      path: '/daily-view',
      name: 'DailyView',
      component: () => import('@/views/DailyView/DailyView'),
      meta: {
        title: '當日預約行事曆',
        action: 'adminView.appointmentCalendarByDay.page',
      },
    },
    ...companyContactMenuRoutes,
    ...parametersRoutes,
    ...basicRoutes,
    ...progressNotificationRoutes,
    ...appointmentRoutes,
    ...pubAptRoutes,
    ...resourceRoutes,
    ...salesRoutes,
    ...subscribeRoutes,
    ...classTicketRoutes,
    ...ecommerceRoutes,
    ...marketLeagueRoutes,
    ...lotteryRoutes,
    ...couponRoutes,
    ...walletRoutes,
    ...rebateRoutes,
    ...pointRoutes,
    ...memberShopRoutes,
    ...externalTransactionRoutes,
    ...rewardActivityRoutes,
    ...rewardPostponeRoutes,
    ...memberCenterRoutes,
    ...operateDashboardRoutes,
    ...otherRoutes,
    // ...monetaryBonusRoutes, 火箭紅心
    ...aiAnalysisRoutes,
    ...sheetExportCenterRoutes,
    { path: '*', redirect: '/branch-shop-options', hidden: true },
  ]
}

const createRouter = () =>
  new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: [...constantRoutes],
  })

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.afterEach((to, from) => {
  console.log('🔥[debug] afterEach')
  if (to.meta.bobPageAction) {
    let scopeId = store.getters.shop
    if (to.meta.scopeType === 'OrgId') scopeId = store.getters.orgId
    const data = {
      scopeId,
      userId: store.getters.user?.id,
      date: dayjs().format('YYYY-MM-DD HH:mm'),
      action: to.meta.bobPageAction,
      path: to.path,
    }
    batch.create(data)
  }
  // const keepAlive = get(to, 'meta.keepAlive')
  // if (keepAlive) { store.commit('app/SET_KEEP_ALIVE', to.name) }
  if (includes(get(keepAliveConfig, `${from.name}.to`), to.name)) {
    store.commit('app/SET_KEEP_ALIVE', from.name)
  }
  // if (get(from, 'meta.group')) {
  //   console.log(get(from, 'meta.group'))
  //   store.commit('app/SET_KEEP_ALIVE', `${get(from, 'meta.group')}RouterViewWrapper`)
  // }
  if (
    !get(keepAliveConfig, to.name) &&
    !Object.values(keepAliveConfig).some((config) => includes(config.to, to.name))
  ) {
    store.dispatch('app/emptyKeepAlive')
  }

  store.commit('app/TOGGLE_PAGE_CHANGING', false)
})

export default router
