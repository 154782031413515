import Layout from '@/layout'

export const appointmentRoutes = [
  // 預約模組
  {
    path: '/reservations',
    component: Layout,
    isGroup: true,
    meta: {
      title: '預約模組',
    },
    children: [
      {
        path: 'settings',
        component: () => import('@/components/RouterViewWrapper.vue'),
        meta: {
          title: '預約管理',
        },
        children: [
          {
            path: 'service-category-setting',
            name: 'ServiceCategorySetting',
            component: () =>
              import('@/views/Reservations/Setting/ServiceCategorySetting'),
            meta: {
              title: '服務類別設定',
              action: 'admin.appointmentCategory.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_CATEGORY_LIST',
            },
          },
          {
            path: 'service-setting',
            name: 'ServiceSetting',
            component: () =>
              import('@/views/Reservations/Setting/ServiceSetting'),
            meta: {
              title: '服務項目設定',
              action: 'admin.appointmentService.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SERVICE_LIST',
              group: 'Appointment',
            },
          },
          {
            path: '/create-reservation-service/:serviceId?',
            name: 'CreateReservationService',
            hidden: true,
            component: () =>
              import('@/views/Reservations/CreateReservationService/CreateReservationService.vue'),
            meta: {
              title: '創建服務項目',
              action: 'admin.appointmentService.page',
              // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SERVICE_LIST',
            },
          },
          {
            path: 'attach-service-setting',
            name: 'AttachServiceSetting',
            component: () =>
              import('@/views/Reservations/Setting/AttachServiceSetting'),
            meta: {
              title: '附加服務設定',
              action: 'admin.appointmentServiceAttach.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_ATTACH_LIST',
            },
          },
          {
            path: 'service-unit-setting',
            name: 'ServiceUnitSetting',
            component: () =>
              import('@/views/Reservations/Setting/ServiceUnitSetting'),
            meta: {
              title: '服務人員設定',
              action: 'admin.appointmentUnit.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_UNIT_LIST',
            },
          },
          {
            path: 'shifts-setting',
            name: 'ShiftClassSetting',
            component: () =>
              import('@/views/Reservations/ShiftClassSetting/ShiftClassSetting.vue'),
            meta: {
              title: '排班班別設定',
              action: 'admin.appointmentScheduleDay.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SHIFT_LIST',
            },
          },
          {
            path: 'shifts-calendar-setting',
            name: 'ShiftsCalendarSetting',
            component: () =>
              import('@/views/ShiftCalendar/ShiftCalendar.vue'),
            meta: {
              title: '排班行事曆設定',
              action: 'admin.appointmentSchedule.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SHIFT_SETTING',
            },
          },
          {
            path: 'shifts-calendar',
            name: 'ShiftsCalendar',
            hidden: true,
            component: () =>
              import('@/views/ShiftCalendarEdit/ShiftCalendarEdit.vue'),
            meta: {
              title: '排班班表設定',
              action: 'admin.appointmentSchedule.page',
              // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SHIFT_SETTING'
            },
          },
        ],
      },
      {
        path: '/reservation-create/:step?',
        name: 'ReservationCreate',
        hidden: true,
        component: () => import('@/views/Reservations/ReservationCreate/ReservationCreate.vue'),
        meta: {
          title: '創建預約',
          action: 'admin.appointmentReservation.page',
        },
      },
      {
        path: 'reservation-list',
        name: 'ReservationList',
        component: () => import('@/views/Reservations/ReservationList/ReservationList.vue'),
        meta: {
          title: '預約列表',
          action: 'admin.appointmentReservation.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_LIST',
        },
      },
      {
        path: 'reservation-calendar',
        name: 'ReservationCalendar',
        component: () => import('@/views/Reservations/ReservationCalendar/ReservationCalendar.vue'),
        meta: {
          title: '預約行事曆',
          action: 'admin.appointmentReservation.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_CALENDAR',
        },
      },
      {
        path: 'reservation-time-space',
        name: 'ReservationTimeSpace',
        component: () => import('@/views/Reservations/ReservationTimeSpace'),
        meta: {
          title: '預約空檔查詢',
          action: 'admin.appointmentScheduleBan.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_UNIT_SPACE_LIST',
        },
      },
    ],
  },
]
