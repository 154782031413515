<template>
  <el-drawer
    title="🔰 權限控制面板"
    :visible.sync="syncShow"
    :append-to-body="true"
    size="auto"
    @close="onClose"
  >
    <div style="padding: 20px">
      <div style="padding-bottom: 20px">
        <el-checkbox v-model="useOriginData">使用原始資料</el-checkbox>
      </div>

      <div class="text-white">
        <el-tabs v-model="curTab" type="card">
          <el-tab-pane label="店家方案" />
          <el-tab-pane label="使用者權限" />
        </el-tabs>
      </div>

      <div class="filters-container">
        <el-input v-model="search" clearable placeholder="搜尋 featureKey" />
        <el-checkbox v-model="testRoute"><span class="test-route-checkbox">測試路由</span></el-checkbox>
      </div>

      <div>
        <ShopFeatureSelector v-show="curTab === '0'" :search="search" :testRoute="testRoute" :useOriginData="useOriginData" />
        <UserFeatureSelector v-show="curTab === '1'" :search="search" :testRoute="testRoute" :useOriginData="useOriginData" />
      </div>

      <el-divider />

      <div class="area-block">
        <p class="area-title">權限檢查</p>
        <FeatureKeyChecker />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'
import ShopFeatureSelector from './components/ShopFeatureSelector.vue'
import UserFeatureSelector from './components/UserFeatureSelector.vue'
import FeatureKeyChecker from './components/FeatureKeyChecker.vue'
import store from '@/store'
import { get } from 'lodash'

export default defineComponent({
  name: 'FeatureKeyDebugger',
  components: { ShopFeatureSelector, UserFeatureSelector, FeatureKeyChecker },
  props: {
    show: Boolean,
  },
  setup (props, { emit }) {
    const targetRef = ref(null)
    const search = ref(null)
    const curTab = ref('')
    const useOriginData = ref(true)
    const syncShow = computed({
      get () {
        return get(store.state, 'app.toggle.featureKeyDebugger')
      },

      set (data) {
        store.commit('app/SET_TOGGLE', { key: 'featureKeyDebugger', toggle: data })
      },
    })

    const testRoute = ref(false)

    const onClose = () => {
      store.commit('app/SET_TOGGLE', { key: 'featureKeyDebugger', toggle: false })
    }

    return {
      targetRef,
      search,
      curTab,
      testRoute,
      onClose,
      syncShow,
      useOriginData,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-drawer {
  @apply bg-black bg-opacity-30;
  backdrop-filter: blur(5px);
}
::v-deep .el-tabs__item {
  @apply text-gray-40;
}
::v-deep .el-tabs__item.is-active {
  @apply text-[#58f993];
}
::v-deep .el-dialog__close{
  @apply text-[#58f993];
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  @apply text-[#58f993];
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  @apply bg-[#58f993];
}

::v-deep .el-drawer__header span {
  @apply text-[#58f993];
}

.filters-container {
  @apply flex items-center gap-[10px];
}

.area-block {
  @apply bg-opacity-20 bg-white rounded-md p-[15px];
}
.area-title {
  @apply text-[#58f993] text-lg font-medium mb-[10px];
}

.test-route-checkbox {
  @apply text-warning;
}
</style>
