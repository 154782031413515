import Layout from '@/layout'

// 行銷模組
export default {
  path: '/marketing',
  component: Layout,
  name: 'Marketing',
  alwaysShow: true,
  isGroup: true,
  meta: {
    title: '行銷模組',
  },
  children: [
    {
      path: 'tags-setting',
      component: () => import('@/components/RouterViewWrapper.vue'),
      meta: {
        title: '行銷設定',
      },
      children: [
        {
          path: '',
          component: () => import('@/views/Marketing/TagsSetting'),
          name: 'TagsSetting',
          meta: {
            title: '標籤設定',
            action: 'admin.mTag.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_TAG_LIST',
          },
        },
        {
          path: 'tags-advanced-search',
          component: () => import('@/views/Marketing/TagsAdvancedSearch'),
          name: 'TagsAdvancedSearch',
          hidden: true,
          meta: {
            title: '標籤設定',
            action: 'admin.mTag.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_TAG_LIST',
          },
        },
      ],
    },

    {
      path: 'settings',
      component: {
        render (c) {
          return c('router-view')
        },
      },

      name: 'MarketingSetting',
      meta: {
        title: '分群推播管理',
      },
      children: [
        {
          path: 'push-message-setting',
          component: () =>
            import('@/views/Marketing/PushMessageManage/PushMessageSetting'),
          name: 'PushMessageSetting',
          meta: {
            title: '分群推播列表',
            action: 'admin.groupPushMessage.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_PUSHMSG_LIST',
          },
        },
        {
          path: 'push-message',
          component: () => import('@/views/Marketing/PushMessage'),
          name: 'PushMessage',
          hidden: true,
          meta: {
            title: '分群推播',
            action: 'admin.groupPushMessage.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_PUSHMSG_CREATE',
          },
        },
        {
          path: 'push-coupon',
          component: () => import('@/views/Marketing/PushCoupon'),
          name: 'PushCoupon',
          hidden: true,
          meta: {
            title: '分群推播',
            action: 'admin.groupPushMessage.page',
            bobPageAction: 'PAGE_VIEW_DASH_SHOP_MARKETING_PUSHMSG_CREATE',
          },
        },
      ],
    },
    {
      path: '/event-marketing-list',
      name: 'EventMarketingList',
      component: () =>
        import('@/views/Marketing/EventMarkingList/EventMarkingList.vue'),
      meta: {
        title: '事件行銷列表',
        action: 'admin.eventTemplateConfig.page',
      },
    },
    {
      path: '/event-marketing-report',
      name: 'EventMarketingReport',
      component: () =>
        import('@/views/Marketing/EventMarketingReport/EventMarketingReport.vue'),
      meta: {
        title: '事件行銷發放報表',
        action: 'admin.eventTemplateConfig.page',
      },
    },
    {
      path: '/event-marketing/:id?',
      name: 'EventMarketingEdit',
      hidden: true,
      component: () =>
        import('@/views/Marketing/EventMarketingEdit/EventMarketingEdit.vue'),
      meta: {
        title: '編輯事件行銷',
        action: 'admin.eventTemplateConfig.page',
      },
    },
    {
      path: '/tracking-link-management',
      name: 'TrackingLinkManagement',
      component: () => import('@/views/Marketing/TrackingLinkManagement'),
      meta: {
        title: '追蹤連結管理',
        action: 'admin.trackingLink.page',
        bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
      },
    },
    {
      path: '/client-mtag-link-management',
      name: 'ClientMTagLinkManagement',
      component: () => import('@/views/Marketing/ClientMTagLinkManagement'),
      meta: {
        title: '線下貼標連結管理',
        action: 'admin.clientMTagLink.page',
        bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
      },
    },
    {
      path: '/editTrackingLink/:id?',
      name: 'EditTrackingLink',
      component: () => import('@/views/Marketing/EditTrackingLink'),
      hidden: true,
      meta: {
        title: '編輯連結管理',
        action: 'admin.trackingLink.page',
        bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
      },
    },
    {
      path: '/editClientMTagLink/:id?',
      name: 'EditClientMTagLink',
      component: () => import('@/views/Marketing/EditClientMTagLink'),
      hidden: true,
      meta: {
        title: '編輯連結管理',
        action: 'admin.clientMTagLink.page',
        bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
      },
    },
    {
      path: '/member-invite-setting',
      name: 'MemberInviteSetting',
      component: () => import('@/views/Marketing/MemberInviteSetting/MemberInviteSetting.vue'),
      meta: {
        title: '推薦會員活動設定',
        action: 'admin.referralActivity.page',
      },
    },
    {
      path: '/member-invite-edit',
      name: 'MemberInviteEdit',
      hidden: true,
      component: () => import('@/views/Marketing/MemberInviteSetting/components/MemberInviteEdit.vue'),
      meta: {
        title: '推薦會員活動邊編輯',
        action: 'admin.referralActivity.page',
      },
    },
    {
      path: '/member-invite-report',
      name: 'MemberInviteReport',
      component: () => import('@/views/Marketing/MemberInviteReport/MemberInviteReport.vue'),
      meta: {
        title: '推薦會員報表',
        action: 'admin.referralActivity.page',
      },
    },
    {
      path: '/member-center-bulletin-list',
      name: 'MemberCenterBulletinList',
      component: () => import('@/views/Marketing/MemberCenterBulletinList/MemberCenterBulletinList.vue'),
      meta: {
        title: '會員中心佈告欄',
        action: 'admin.memberCenterAd.page',
      },
    },
    {
      path: '/member-center-bulletin-edit/:id?',
      name: 'MemberCenterBulletinEdit',
      component: () =>
        import(
          '@/views/Marketing/MemberCenterBulletinEdit/MemberCenterBulletinEdit.vue'
        ),
      hidden: true,
      meta: {
        action: 'admin.memberCenterAd.page',
      },
    },
    {
      path: '/member-center-bulletin-detail-list',
      name: 'MemberCenterBulletinDetailList',
      component: () => import('@/views/Marketing/MemberCenterBulletinDetailList/MemberCenterBulletinDetailList.vue'),
      meta: {
        title: '佈告資訊頁列表',
        action: 'admin.clientAnnouncement.page',
      },
    },
    // 佈告欄
    {
      path: '/member-center-bulletin-detail/edit/:id?',
      name: 'MemberCenterBulletinDetailEdit',
      component: () => import('@/views/MemberCenterBulletinDetailEdit/MemberCenterBulletinDetailEdit.vue'),
      hidden: true,
      meta: {
        title: '佈告資訊頁設定',
        action: 'admin.clientAnnouncement.page',
      },
    },
  ],
}
