<template>
  <el-button class="add-btn" :disabled="disabled" @click="$emit('click')">
    <div class="flex justify-center items-center">
      <p style="margin-right: 5px">{{ content }}</p>
      <MaterialIcon :size="24">
        add_outlined
      </MaterialIcon>
    </div>
  </el-button>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon.vue'
export default {
  name: 'AddButton',
  components: { MaterialIcon },
  props: {
    content: { type: String, default: '新增' },
    disabled: { type: Boolean, default: false },
  },
}
</script>

<style lang="postcss" scoped>
.add-btn {
  @apply flex justify-center items-center;
  @apply text-normal text-info py-[6px] px-[22px];
  /* border: solid 1px var(--info) !important; */
  @apply border border-info bg-transparent leading-[28px];
}
.add-btn:focus {
  @apply text-info border border-info;
}

.add-btn:hover {
  @apply bg-info text-white;
}
</style>
