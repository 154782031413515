import { reactive } from 'vue'
import { useFetch } from '@/use/fetch'
import { useShop } from './shop'
import { timeout } from '@/utils/helper'

export const useFetchAll = (fetchCountAPI, fetchAPI) => {
  const { simpleFetch } = useFetch()
  const { shopId } = useShop()
  const fetchData = reactive({
    total: 0,
    current: 0,
    data: [],
    loading: false,
  })

  const execute = async (payload) => {
    fetchData.loading = true
    await simpleFetch(fetchCountAPI, { shopId: shopId.value }, (res) => {
      fetchData.total = res.count || res
    })
    if (!fetchData.total) {
      fetchData.loading = false
      return
    }

    let start = 0
    const _limit = 100

    while (fetchData.data.length < fetchData.total) {
      await simpleFetch(fetchAPI, {
        ...payload,
        start,
        limit: _limit,
      }, (res) => {
        fetchData.data.push(...res)
        fetchData.current += res.length
        start += _limit
      })
    }
    fetchData.loading = false
  }

  return { fetchData, execute }
}

export const fetchAll = async (fetchAPI, payload) => {
  let start = 0
  const data = []
  const _limit = 100

  while (true) {
    const [res, err] = await fetchAPI({
      ...payload,
      start,
      limit: _limit,
    })
    if (err) {
      return [null, err]
    }
    start += _limit
    data.push(...res)
    if (res.length < _limit) {
      break
    }
  }
  return [data, null]
}
