import Layout from '@/layout'

export const subscribeRoutes = [
  // 預約模組
  {
    path: '/subscribe',
    component: Layout,
    isGroup: true,
    alwaysShow: true,
    meta: {
      title: '訂閱模組',
    },
    children: [
      {
        path: 'subscribe-plan-list',
        name: 'SubscribePlanList',
        component: () => import('@/views/Subscribe/SubscribePlan/SubscribePlanList.vue'),
        meta: {
          title: '訂閱方案',
          action: 'admin.subscriptionPlan.page',
        },
      },
      {
        path: 'subscribe-plan/:id?',
        name: 'SubscribePlanEdit',
        component: () => import('@/views/Subscribe/SubscribePlanEdit/SubscribePlanEdit.vue'),
        hidden: true,
        meta: {
          title: '編輯訂閱方案',
          action: 'admin.subscriptionPlan.page',
        },
      },
      {
        path: 'subscribe-combine-list',
        name: 'SubscribeCombineList',
        component: () => import('@/views/Subscribe/SubscribeCombine/SubscribeCombineList.vue'),
        meta: {
          title: '品項組合',
          action: 'admin.subscriptionEquityPack.page',
        },
      },
      {
        path: 'subscribe-combine/:id?',
        name: 'SubscribeCombineEdit',
        hidden: true,
        component: () => import('@/views/Subscribe/SubscribeCombineEdit/SubscribeCombineEdit.vue'),
        meta: {
          title: '編輯品項組合',
          action: 'admin.subscriptionEquityPack.page',
        },
      },
      {
        path: 'subscribe-contract-list',
        name: 'SubscribeContractList',
        component: () => import('@/views/Subscribe/SubscribeContract/SubscribeContractList.vue'),
        meta: {
          title: '訂閱合約',
          action: 'admin.subscriptionContract.page',
        },
      },
      {
        path: 'subscribe-contract/:id?',
        name: 'SubscribeContractEdit',
        hidden: true,
        component: () => import('@/views/Subscribe/SubscribeContractEdit/SubscribeContractEdit.vue'),
        meta: {
          title: '編輯訂閱合約',
          action: 'admin.subscriptionContract.page',
        },
      },
      {
        path: 'subscribe-record-list',
        name: 'SubscribeRecordList',
        component: () => import('@/views/Subscribe/SubscribeRecord/SubscribeRecordList.vue'),
        meta: {
          title: '訂閱紀錄',
          action: 'admin.subscriptionOrder.page',
        },
      },
      {
        path: 'subscribe-record/:id?',
        name: 'SubscribeRecordEdit',
        hidden: true,
        component: () => import('@/views/Subscribe/SubscribeRecordEdit/SubscribeRecordEdit.vue'),
        meta: {
          title: '查看訂閱紀錄',
          action: 'admin.subscriptionOrder.page',
        },
      },
      {
        path: 'product-edit/:id?',
        name: 'SubscriptionProductEdit',
        component: () =>
          import(
            '@/views/MemberShop/SubscriptionProductEdit/SubscriptionProductEdit.vue'
          ),
        hidden: true,
        meta: {
          action: 'admin.subscriptionPlan.page',
        },
      },
    ],
  },
]
