<template>
  <div class="m-icon" :style="`line-height: ${size}px`">
    <span
      class="material-icons"
      :class="{'cursor-pointer': pointer}"
      :style="`font-size: ${size}px; width: ${size}px; color: ${color} `"
    >
      <slot />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MaterialIcon',
  props: {
    color: { type: String, default: 'var(--base)' },
    size: { type: [String, Number], default: 24 },
    pointer: Boolean,
  },
})
</script>

<style scoped lang="postcss">
.m-icon {
  @apply w-[fit-content] h-[fit-content] overflow-hidden;
  @apply flex items-center justify-center;
}
</style>
