<template>
  <div>
    <el-button
      v-if="!hideCopy"
      type="text"
      data-testid="table-edit-btn-group-copy-btn"
      class="btn edit"
      :disabled="copyDisabled"
      :loading="copyLoading"
      @click="$emit('copy')"
    >
      {{ copyBtn }}
    </el-button>
    <el-button
      v-if="!hideEdit"
      type="text"
      data-testid="table-edit-btn-group-edit-btn"
      class="btn edit"
      :disabled="editDisabled"
      :loading="editLoading"
      @click="$emit('edit')"
    >
      {{ editBtn }}
    </el-button>
    <el-button
      v-if="!hideDelete"
      type="text"
      data-testid="table-edit-btn-group-delete-btn"
      class="btn delete"
      :disabled="deleteDisabled"
      :loading="deleteLoading"
      @click="$emit('delete')"
    >
      {{ deleteBtn }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'TableEditBtnGroup',
  props: {
    copyBtn: {
      type: String,
      default: '複製',
    },
    editBtn: {
      type: String,
      default: '編輯',
    },
    deleteBtn: {
      type: String,
      default: '刪除',
    },
    copyDisabled: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    hideCopy: {
      type: Boolean,
      default: true,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    copyLoading: {
      type: Boolean,
      default: false,
    },
    editLoading: {
      type: Boolean,
      default: false,
    },
    deleteLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" scoped>
.btn {
  @apply underline text-normal font-normal;
}
.edit {
  @apply text-action;
}

.delete {
  @apply text-danger;
}

::v-deep .el-button.is-disabled.el-button--text {
  @apply text-gray-40;
}
</style>
