import Layout from '@/layout'

export const pubAptRoutes = [
  // 預約模組
  {
    path: '/public-appointment',
    component: Layout,
    isGroup: true,
    alwaysShow: true,
    meta: {
      title: '跨境預約模組',
      // action: 'admin.pubAptConfig.page',
    },
    children: [
      {
        path: 'reservation-list',
        name: 'PubAptReservationList',
        component: () => import('@/views/PubApt/ReservationList/ReservationList.vue'),
        meta: {
          title: '跨境訪客預約人次',
          action: 'admin.appointmentVisitorPreOrder.page',
        },
      },
      {
        path: 'content-setting',
        name: 'PubAptContentSetting',
        component: () => import('@/views/PubApt/ContentSetting/ContentSetting.vue'),
        meta: {
          title: '首頁內容設定',
          action: 'admin.pubAptConfig.page',
        },
      },
      {
        path: 'edit-banner-setting',
        name: 'EditBannerSetting',
        component: () => import('@/views/PubApt/EditBannerSetting/EditBannerSetting.vue'),
        hidden: true,
        meta: {
          title: '編輯Banner區塊',
          action: 'admin.pubAptConfig.page',
        },
      },
      {
        path: 'edit-custom-setting',
        name: 'EditCustomSetting',
        component: () => import('@/views/PubApt/EditCustomSetting/EditCustomSetting.vue'),
        hidden: true,
        meta: {
          title: '編輯自定義區塊',
          action: 'admin.pubAptConfig.page',
        },
      },
    ],
  },
]
