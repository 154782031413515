<template>
  <div id="app">
    <template v-if="isOnline">
      <AppLoading v-if="!loaded || pageChanging" />
      <router-view v-show="!pageChanging" />
      <Commander
        v-if="$store.state.app.commander"
        @close="$store.commit('app/SHOW_COMMANDER', false)"
      />
      <FeatureKeyDebugger v-if="loaded && usePermissionsDebugger" />
    </template>
    <NoNetwork v-else />
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onBeforeMount,
  reactive,
  toRef,
  ref,
  onMounted,
} from "vue"
import { mapGetters } from "vuex"
import ls from "@/utils/localstorage"
import configuration from "@/configuration"
import Commander from "@/components/Commander/Commander.vue"
import AppLoading from "@/components/AppLoading.vue"
import FeatureKeyDebugger from "@/components/FeatureKeyDebugger/FeatureKeyDebugger.vue"
import NoNetwork from "./components/NoNetwork.vue"
import hotkeys from "hotkeys-js"
import { get } from "lodash"
import store from "@/store"
import { FindShopPoint } from "@/api/shopPoint"
import { FindShopRebate } from "@/api/rebate"
import { usePermissions } from "@/use/permissions"
import { useNewsHint } from "@/use/useNewsHint"
import { setUserProperties, userPropertiesConfig } from "@/use/useGtag"

export default defineComponent({
  name: "App",
  components: { Commander, FeatureKeyDebugger, AppLoading, NoNetwork },
  setup() {
    const { checkAction } = usePermissions()
    const isOnline = ref(navigator?.onLine)
    const shopId = computed(() => get(store.getters, "shop"))
    const usePermissionsDebugger = computed(() =>
      get(store.getters, "app/useSidebarSearch")
    )
    const pageChanging = computed(() => get(store.state, "app.pageChanging"))
    const getMarketingPermission = async (shopId) => {
      let marketingItem = {
        classTicket: false,
        coupon: false,
        point: false,
        cashback: false,
      }

      let pointCheck = false
      if (checkAction("admin.shopPoint.page")) {
        const [res, err] = await FindShopPoint({ shopId })
        if (res) pointCheck = get(res, "pointClientId") !== null
        if (err) pointCheck = false
      }
      let cashbackCheck = false
      if (checkAction("admin.shopCashback.page")) {
        const [res, err] = await FindShopRebate({ shopId })
        if (res) cashbackCheck = Object.keys(res).length !== 0
        if (err) cashbackCheck = false
      }
      marketingItem = {
        classTicket: checkAction("admin.classTicket.page"),
        coupon:
          checkAction("admin.coupon.page") ||
          checkAction("admin.couponExchange.page"),
        point: pointCheck,
        cashback: cashbackCheck,
      }

      store.commit("permission/SET_MARKETINGITEM_PERMISSIONS", {
        key: "marketingItem",
        data: marketingItem,
      })
    }

    const { checkUnreadArticles } = useNewsHint()

    onBeforeMount(async () => {
      if (!ls.getToken()) return
      checkUnreadArticles()
    })

    onMounted(() => {
      // 網路連接時觸發
      window.addEventListener("online", updateOnlineStatus)
      // 網路斷開時觸發
      window.addEventListener("offline", updateOnlineStatus)

      function updateOnlineStatus() {
        isOnline.value = navigator?.onLine
        console.log("this.isOnline", isOnline.value)
      }
    })

    return {
      usePermissionsDebugger,
      getMarketingPermission,
      shopId,
      isOnline,
      pageChanging,
    }
  },
  data: () => ({
    loaded: false,
  }),
  computed: {
    ...mapGetters(["shopPlanIsReady"]),
  },
  watch: {
    shopPlanIsReady: {
      handler: function (newVal) {
        if (newVal) {
          setTimeout(() => {
            this.getMarketingPermission(this.shopId)
          }, 500)
        }
      },
    },
  },
  async mounted() {
    window.$message = this.$message
    console.log("window.gtag", window.gtag)

    console.log("appRuntime", configuration("appRuntime"))

    this.setTemp()
    // 重新整理前暫存 token user
    window.onbeforeunload = () => {
      this.storeTemp()
    }
    this.$router.onReady(() => {
      this.loaded = true
    })

    setUserProperties(userPropertiesConfig.shopId, this.shopId)
  },
  methods: {
    configuration,
    storeTemp() {
      const token = this.$store.getters.token
      const user = this.$store.getters.user
      const shop = this.$store.getters.shop
      ls.setTemp({ token, user, shop })
    },

    setTemp() {
      const { token, user, shop } = ls.getTemp() || {
        token: ls.getToken(),
        user: ls.getUser(),
        shop: ls.getShop(),
      }
      this.$store.commit("auth/SET_TOKEN", token)
      this.$store.commit("user/SET_USER", user)
      this.$store.commit("user/SET_NOW_SHOP", shop)

      ls.removeTemp()
    },
  },
})
</script>

<style>
/* pagination */
.table-pagination {
  padding-bottom: 50px;
}

.el-scrollbar .el-scrollbar__bar {
  opacity: 1 !important;
}
html,
body,
#app {
  @apply h-full;
}
</style>
