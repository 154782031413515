import Layout from '@/layout'

export const otherRoutes = [
  {
    path: '/other',
    component: Layout,
    name: 'Other',
    alwaysShow: true,
    isGroup: true,
    hidden: true,
    children: [
      // {
      //   path: '/pos-payment-recognize',
      //   name: 'PosPaymentRecognize',
      //   hidden: true,
      //   component: () => import('@/views/Other/PosPaymentRecognize.vue'),
      //   meta: {
      //     title: '第三方POS支付方式認列',
      //   //   action: 'admin.shopPoint.page',
      //   },
      // },
      {
        path: '/pos-pospal',
        name: 'PosPospal',
        hidden: true,
        component: () => import('@/views/Other/PosPospal.vue'),
        meta: {
          title: '第三方交易-瑞乘（銀豹）規則說明',
        //   action: 'admin.shopPoint.page',
        },
      },
    ],
  },
]
