import { pageActionRecord } from '@/api/bob'

class BatchCreate {
  constructor (model, {
    maxCreateInterval = 5000,
    checkInterval = 500,
    maxCount = 5,
  } = {}) {
    this.waitCreate = []
    this.model = model
    this.maxCreateInterval = maxCreateInterval
    this.checkInterval = checkInterval
    this.lastAddCreate = Date.now()
    this.maxCount = maxCount
  }

  create (data) {
    this.waitCreate.push(data)
    this.lastAddCreate = Date.now()
  }

  start () {
    this.interval = setInterval(async () => {
      while (
        this.waitCreate.length > (this.maxCount - 1) ||
        (this.waitCreate.length > 0 && ((Date.now() - this.lastAddCreate) > this.maxCreateInterval))
      ) {
        const count = Math.min(this.waitCreate.length, this.maxCount)
        const dataSet = this.waitCreate.splice(0, count)
        if (dataSet.length) {
          pageActionRecord({ data: dataSet })
          // dataSet.forEach((item) => {

          // })
        }
        // await this.model.batchPut(data)
      }
    }, this.checkInterval)
  }
}

export const batch = new BatchCreate()
batch.start()
export default BatchCreate
