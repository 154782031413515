import {
  GetShops,
  GetShopsCount,
  GetShopById,
  GetShopModules,
  GetShopPlan,
} from '@/api/shop'
import { FindReservationParameter } from '@/api/reservation'

const state = {
  count: 0,
  list: [],
  shop: null,
  timeUnit: 30,
  shopConfig: {},
}

const mutations = {
  SET_SHOP_COUNT: (state, count) => {
    state.count = count
  },
  SET_SHOP_LIST: (state, list) => {
    state.list = list
  },
  SET_SHOP: (state, shop) => {
    state.shop = shop
  },
  SET_TIME_UNIT: (state, timeUnit) => {
    state.timeUnit = timeUnit
  },
}

const actions = {
  async findShop ({ commit }, { shopId }) {
    const [res, err] = await GetShopById(shopId)
    if (err) return console.log(err || err.msg)
    state.shopConfig = res
    return res
  },
  // get user info
  async getShopList ({ commit }) {
    try {
      const count = await GetShopsCount()
      let start = 0
      const limit = 5
      let shops = []

      shops = await GetShops({ start, limit })

      while (shops.length < count) {
        start += 5
        shops = shops.concat(await GetShops({ start, limit }))
      }

      commit('SET_SHOP_COUNT', count)
      commit('SET_SHOP_LIST', shops)

      return shops
    } catch (error) {
      console.log(error)
    }
  },
  // get user info
  // eslint-disable-next-line no-empty-pattern
  async getShopContractById ({}, { shopId }) {
    try {
      const shop = await GetShopById(shopId)
      const { ContractPlan } = shop
      return ContractPlan
    } catch (error) {
      console.log(error)
    }
  },
  async getShopModules (context, { shopId }) {
    try {
      return await GetShopModules(shopId)
      // return await GetShopPlan(shopId)
    } catch (error) {
      console.log(error)
    }
  },
  async getShopPlan (context, { shopId, message }) {
    try {
      return await GetShopPlan(shopId)
    } catch (error) {
      console.log(error)
      message.error(error)
      return false
    }
  },
  async getShopReservationConfig ({ commit }, { shopId }) {
    try {
      const res = await FindReservationParameter({ shopId })
      commit('SET_TIME_UNIT', res.timeUnit)
      return res
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
