import ls from 'local-storage'
import { GetAnnouncement } from '@/api/homePage'
import { filter, find, includes, map, omit } from 'lodash'
import localStoreKeys from '@/config/localStoreKeys'
import vuexStore from '@/store'
import dayjs from '@/lib/dayjs'

export const useNewsHint = () => {
  const updateStore = (store) => {
    ls.set(localStoreKeys.announcements, store)
  }

  const useStoreValue = () => {
    const store = ls.get(localStoreKeys.announcements)
    if (store) return store
    const data = {
      lastest: [],
      readed: [],
    }
    updateStore(data)
    return data
  }

  const countUnread = () => {
    cleanUnread()
    const store = useStoreValue()
    return store.lastest.length - store.readed.length
  }

  const over30Days = (dateTime) => {
    const before30DaysDate = dayjs().subtract(30, 'days')
    return dayjs(dateTime).isBefore(before30DaysDate, 'd')
  }

  function isUnread (articleId) {
    const store = useStoreValue()

    const exist = find(store.lastest, { id: articleId })
    if (!exist) return false

    return !over30Days(exist.publishedAt) && !includes(store.readed, articleId)
  }

  const updateUnreadVuexState = () => {
    const unreadCount = countUnread()
    if (unreadCount > 0) vuexStore.commit('app/SET_HAVE_UNREAD_ANNOUNCEMENTS', true)
    else if (unreadCount <= 0) vuexStore.commit('app/SET_HAVE_UNREAD_ANNOUNCEMENTS', false)
  }

  const cleanUnread = () => {
    const store = useStoreValue()
    const lastest = store.lastest
    const readed = store.readed

    const exist = filter(readed, (id) => find(lastest, { id }))
    store.readed = exist
    updateStore(store)
  }

  const fetchLatestArticles = async () => {
    const [res, err] = await GetAnnouncement({ start: 0, limit: 50 })
    if (err) {
      window.$message.error(err.msg || err)
      return
    }
    return filter(res, (item) => !over30Days(item.publishedAt))
  }

  // 每次加載文章列表時執行
  async function checkUnreadArticles () {
    // 獲取最新文章
    const latestArticles = await fetchLatestArticles()

    // 獲取本地已存儲的文章
    const store = useStoreValue()

    // 更新本地存儲
    store.lastest = map(latestArticles, (item) => omit(item, ['content']))
    updateStore(store)

    cleanUnread()
    updateUnreadVuexState()
  }

  // 文章被讀取時
  function articleRead (articleId) {
    // 更新已讀映射
    const store = useStoreValue()
    if (includes(store.readed, articleId)) return
    store.readed.push(articleId)
    updateStore(store)
    updateUnreadVuexState()
  }

  return { articleRead, checkUnreadArticles, isUnread, countUnread }
}
