<template>
  <div class="loader-container">
    <div class="spinner">
      <div class="double-bounce1" />
      <div class="double-bounce2" />
    </div>
    <div class="loading-text" style="text-align: center; font-weight: 500; font-size: 18px;">
      <p>畫面載入中</p>
      <div v-if="showSlowNetwork">
        <p>偵測您當前網路速度較慢 <span v-show="downloadSpeed">(下載速度小於100kbps)</span></p>
        <p v-show="downloadSpeed">當前下載速度：{{ downloadSpeed }} kbps</p>
        <p>請耐心等候</p>
      </div>
    </div>

    <!-- <OhbotFooter /> -->
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue'
// import OhbotFooter from '@/components/OhbotFoooter.vue'

export default defineComponent({
  name: 'AppLoading',
  // components: { OhbotFooter },
  props: {
    slowNetwork: Boolean,
  },
  setup () {
    const downloadSpeed = ref(0)
    const showSlowNetwork = ref(false)

    onBeforeMount(() => {
      setTimeout(() => {
        showSlowNetwork.value = true
      }, 5000)

      const start = new Date().getTime()
      const img = new Image()
      img.onload = function () {
        console.log('img onload')
        const end = new Date().getTime()
        const duration = (end - start) / 1000
        const bitsLoaded = 5000 * 8
        const speedBps = (bitsLoaded / duration).toFixed(2)
        const speedKbps = (speedBps / 1024).toFixed(2)
        console.log(`${speedKbps}kbps`)
        console.log(speedKbps, speedKbps < 100)
        if (speedKbps < 100) {
          downloadSpeed.value = speedKbps
          showSlowNetwork.value = true
        }
      }
      img.src = require('@/assets/blank.webp')
    })

    return { downloadSpeed, showSlowNetwork }
  },
})
</script>

<style lang="postcss" scoped>
    .loader-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }

    .loading-text {
      color: #c8c8c8;
      letter-spacing: 2px;
    }

    .spinner {
      width: 50px;
      height: 50px;

      position: relative;
      /* margin: 100px auto; */
      margin: 30px;
    }

    .double-bounce1,
    .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #c8c8c8;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;

      -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
      animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .double-bounce2 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }

    @-webkit-keyframes sk-bounce {

      0%,
      100% {
        -webkit-transform: scale(0.0)
      }

      50% {
        -webkit-transform: scale(1.0)
      }
    }

    @keyframes sk-bounce {

      0%,
      100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
      }

      50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
    }
</style>
