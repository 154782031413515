import router from './router'
import store from './store'
import { get } from 'lodash'
import { Message } from 'element-ui'
import ls from '@/utils/localstorage' // get token from cookie
import { getPageTitle, setFavicon } from '@/utils/helper' // no redirect whitelist
import { setOrgConfig } from './orgConfig'
import { keepAliveConfig } from '@/config/keepAlive'
const whiteList = [
  '/login',
  /^(\/read-member-history-file\/).+/,
]

router.beforeEach(async (to, from, next) => {
  console.group('page change')
  console.log('from', from.name)
  console.log('to', to.name)
  console.groupEnd()
  let appTheme = ''
  if (to.name !== from.name) store.commit('app/TOGGLE_PAGE_CHANGING', true)
  if (get(keepAliveConfig, `${to.name}`)) {
    store.commit('app/SET_KEEP_ALIVE', to.name)
  }
  if (!from.name) appTheme = await setOrgConfig()

  if (!from.name && to.path.includes('/order-detail/')) {
    const transferPath = {
      path: to.path,
      query: to.query,
    }
    ls.setNeedTransfer(transferPath)
  }

  const targetTheme = appTheme || store.getters.appTheme

  // set page title
  document.title = getPageTitle(targetTheme, to.meta.title)

  // set favicon
  setFavicon(targetTheme)

  // determine whether the user has logged in
  const hasToken = ls.getToken()

  if (to.path === '/auth-login') return next()
  if (to.path === '/not-found') return next()
  else if (hasToken) {
    if (to.path === '/login' || to.path === '/') {
      // if is logged in, redirect to the home page
      next({ path: '/branch-shop-options' })
    } else {
      if (store.getters.shop && !get(store.state, 'shop.shopConfig.id')) store.dispatch('shop/findShop', { shopId: store.getters.shop })
      const hasRoles = store.getters.role
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          const { features, shopPlan } = await store.dispatch('user/getMe')

          // generate accessible routes map based on role
          const accessRoutes = await store.dispatch('permission/generateRoutes', { features, shopPlan })

          // dynamically add accessible routes
          accessRoutes.map(item => router.addRoute(item))

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history
          next({ ...to, replace: true })
        } catch (error) {
          if (error === 503) return alert('系統維護中，將於維護作業完成後恢復相關功能')
          // remove token and go to Login page to re-Login
          await store.dispatch('auth/logout')
          await store.dispatch('user/deleteAccount', { token: hasToken })
          Message.error(error || 'Has Error')
          next('/login')
        }
      }
    }
  } else {
    /* has no token */
    const whiteUrl = whiteList.find(l => {
      return to.path.indexOf(l) !== -1 || to.fullPath.match(l)
    })
    if (whiteUrl) {
      // in the free Login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the Login page.
      next('/login')
    }
  }
})
