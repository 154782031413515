import Layout from '@/layout'
import { feature } from '@/constants/permission'
// import configuration from '@/configuration'

export const ecommerceRoutes = [
  // 電商模組
  {
    path: '/online-shop',
    component: Layout,
    name: 'Ecommerce',
    // hidden: !(configuration('ecommerceModule') === 'true'),
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '電商模組',
    },
    children: [
      {
        path: '/ecommerce/basic',
        alwaysShow: true,
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '基本設定',
        },
        children: [
        ],
      },

      {
        path: '/ecommerce/manage',
        alwaysShow: true,
        component: () => import('@/components/RouterViewWrapper.vue'),
        meta: {
          title: '頁面設定',
        },
        children: [
          {
            path: '/ecommerce/home-setting-edit',
            name: 'HomeSetting',
            hidden: true,
            component: () => import('@/views/Ecommerce/HomeSetting/HomeSetting'),
            meta: {
              title: '首頁設定',
              feature: feature.Ecommerce,
              action: 'admin.ecommercePage.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_HOME_SETTING',
            },
          },
          {
            path: '/ecommerce/home-setting-view',
            name: 'HomeView',
            component: () => import('@/views/Ecommerce/HomeView/HomeSettingView'),
            meta: {
              title: '首頁設定',
              feature: feature.Ecommerce,
              action: 'admin.ecommercePage.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_HOME_SETTING',
            },
          },
          {
            path: 'other-page-setting',
            name: 'OtherPageSetting',
            component: () => import('@/views/Ecommerce/OtherPageSetting/OtherPageSetting'),
            meta: {
              title: '其他頁面設定',
              action: 'admin.ecommercePage.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_PAGE_LIST',
            },
          },
          {
            path: 'other-page-edit/:pageKey?',
            name: 'OtherPageEdit',
            hidden: true,
            component: () => import('@/views/Ecommerce/OtherPageSetting/components/OtherPageEdit'),
            meta: {
              title: '其他頁面設定',
              action: 'admin.ecommercePage.page',
            },
          },

          {
            path: '/ecommerce/navbar-setting',
            name: 'NavbarSetting',
            component: () => import('@/views/Ecommerce/NavbarSetting/NavbarSetting'),
            meta: {
              title: '上層欄功能設定',
              action: 'admin.ecommerceNavConfig.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_NAVBAR_SETTING',
            },
          },
          {
            path: '/ecommerce/sidebar-setting',
            name: 'SidebarSetting',
            component: () => import('@/views/Ecommerce/SidebarSetting/SidebarSetting'),
            meta: {
              title: '側邊欄功能設定',
              action: 'admin.ecommerceSidebarConfig.page',
            },
          },
          {
            path: '/ecommerce/footer-setting',
            name: 'FooterSetting',
            component: () => import('@/views/Ecommerce/FooterSetting/FooterSetting'),
            meta: {
              title: '底部功能設定',
              action: 'admin.ecommerceFooterConfig.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_FOOTER_SETTING',
            },
          },
        ],
      },

      {
        path: '/ecommerce/product',
        alwaysShow: true,
        component: () => import('@/components/RouterViewWrapper.vue'),
        meta: {
          title: '商品設定',
        },
        children: [
          {
            path: 'product-create',
            name: 'ProductCreate',
            hidden: true,
            component: () => import('@/views/Ecommerce/ProductCreate/ProductCreate.vue'),
            meta: {
              title: '新增商品',
              action: 'admin.ecommerceProduct.page',
              type: 'create',
            },
          },
          {
            path: '/product-modify/:id',
            name: 'ProductModify',
            hidden: true,
            component: () => import('@/views/Ecommerce/ProductCreate/ProductCreate.vue'),
            meta: {
              title: '編輯商品',
              action: 'admin.ecommerceProduct.page',
              type: 'modify',
            },
          },

          {
            path: 'product-category-setting',
            name: 'ProductCategorySetting',
            component: () => import('@/views/Ecommerce/ProductCategory/ProductCategorySetting'),
            meta: {
              title: '商品類別設定',
              action: 'admin.ecommerceCategory.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_CATEGORY_LIST',
            },
          },

          {
            path: 'product-setting',
            name: 'EcommerceProductSetting',
            component: () => import('@/views/Ecommerce/ProductSetting'),
            meta: {
              title: '商品項目設定',
              action: 'admin.ecommerceProduct.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_PRODUCT_LIST',
            },
          },

        ],
      },

      {
        path: '/ecommerce/promotion',
        alwaysShow: true,
        component: {
          render (c) {
            return c('router-view')
          },
        },
        meta: {
          title: '活動優惠設定',
        },
        children: [
          {
            path: 'activity-setting',
            name: 'ActivitySetting',
            component: () => import('@/views/Ecommerce/ActivitySetting'),
            meta: {
              title: '活動館優惠設定',
              action: 'admin.ecommerceActivity.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_ACTIVITY_LIST',
            },
          },
          {
            path: 'storewide-activity-Setting',
            name: 'StorewideActivitySetting',
            component: () =>
              import('@/views/Ecommerce/StorewideActivitySetting'),
            meta: {
              title: '全館優惠設定',
              action: 'admin.ecommerceActivity.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_ACTIVITY_STOREWIDE_LIST',
            },
          },
          {
            path: 'promo-setting',
            name: 'PromoSetting',
            component: () => import('@/views/Ecommerce/PromoSetting'),
            meta: {
              title: '折扣碼設定',
              action: 'admin.ecommercePromoCode.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_PROMO_LIST',
            },
          },
        ],
      },
      {
        path: '/order-manage',
        name: 'OrderManage',
        component: () => import('@/views/Ecommerce/OrderManage'),
        meta: {
          title: '訂單管理',
          feature: feature.Ecommerce,
          action: 'admin.ecommerceOrder.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_ECOMMERCE_ORDER_LIST',
        },
      },
      {
        path: '/order-detail/:id',
        name: 'OrderDetail',
        hidden: true,
        component: () => import('@/views/Ecommerce/EcommerceOrderDetail/EcommerceOrderDetail'),
        meta: {
          title: '訂單詳情',
          feature: feature.Ecommerce,
          action: 'admin.ecommerceOrder.page',
        },
      },
    ],
  },
]
