import _dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import weekday from 'dayjs/plugin/weekday'
import isoWeek from 'dayjs/plugin/isoWeek'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/zh-cn'
import { timeZone } from '@/config/app'
import { isISODateString } from '@/utils/date'

_dayjs.extend(utc)
_dayjs.extend(timezone)
_dayjs.extend(isSameOrBefore)
_dayjs.extend(isBetween)
_dayjs.extend(weekday)
_dayjs.extend(isoWeek)
_dayjs.extend(duration)
_dayjs.locale('zh-cn')
// _dayjs.tz.setDefault(timeZone || 'Asia/Taipei')

// export default (dateTime, timeZone) => {
//   if (dateTime) {
//     if (isISODateString(dateTime)) dateTime = new Date(dateTime)
//   }
//   if (timeZone) return _dayjs.tz(dateTime, timeZone)
//   if (!dateTime && timeZone) return _dayjs.tz(new Date(), timeZone)
//   else if (!dateTime && !timeZone) return _dayjs.tz(new Date())
//   return _dayjs.tz(dateTime)
// }

export default _dayjs
