import { admin, admin2 } from '@/api/instance'

//= > 創建店家
export const CreateShop = async ({ imageId, name, phone, address, email, industry, contractPlanId }) => {
  const res = await admin({
    method: 'post',
    url: '/shop',
    data: {
      imageId,
      name,
      phone,
      address,
      email,
      industry,
      contractPlanId,
    },
  })

  return res.data
}

//= > 取得店家
export const GetShops = async ({ start, limit, name }) => {
  const res = await admin({
    method: 'get',
    url: '/shop',
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得指定店家
export const GetShopById = async (shopId) => {
  return await admin2({
    method: 'get',
    url: `/shop/${shopId}`,
  })
}

//= > 店家開啟模組查詢
export const GetShopModules = async (shopId) => {
  const res = await admin({
    method: 'GET',
    url: `/shop/${shopId}/module`,
  })
  return res.data
}

//= > 店家方案功能權限與限制查詢
export const GetShopPlan = async (shopId) => {
  const res = await admin({
    method: 'GET',
    url: `/shop/${shopId}/shopPlan`,
  })
  return res.data
}

//= > 取得總店家數量
export const GetShopsCount = async ({ name }) => {
  const res = await admin({
    method: 'get',
    url: '/shop/count',
    params: {
      name,
    },
  })

  return res.data.count
}

//= > 更新店家
export const UpdateShop = async ({
  shopId,
  imageId,
  name,
  phone,
  address,
  email,
  industry,
  contractPlanId,
}) => {
  const res = await admin({
    method: 'put',
    url: `/shop/${shopId}`,
    data: {
      imageId,
      name,
      phone,
      address,
      email,
      industry,
      contractPlanId,
    },
  })

  return res.data
}

//! 店家設定
export const FindLineConfig = async ({ shopId }) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/lineConfig`,
  })

  return res.data
}

export const UpdateLineConfig = async ({
  shopId,
  lineId,
  channelId,
  channelSecret,
  channelAccessToken,
}) => {
  const res = await admin({
    method: 'put',
    url: `/${shopId}/lineConfig`,
    data: {
      lineId,
      channelId,
      channelSecret,
      channelAccessToken,
    },
  })

  return res.data
}

export const CreateLiff = async ({ shopId, name }) => {
  const res = await admin({
    method: 'post',
    url: `/${shopId}/lineLiff`,
    data: {
      name,
    },
  })

  return res.data
}

export const GetLiff = async ({ shopId }) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/lineLiff`,
  })

  return res.data
}

export const UpdateLinePushConfig = async ({
  shopId,
  appointmentOpen,
  appointmentChangeTime,
  appointmentCancel,
  appointmentTomorrow,
  appointmentEnd,
  walletDeposit,
  walletUse,
  classTicketOpen,
  classTicketUse,
  phoneVerifyHelp,
  cancelClassTicket,
  cancelClassTicketUse,
  allianceActivityAddQualification,
  couponRecordOpen,
  couponRecordExpireNotification,
  appointmentDepositRefund,
  shopCashbackExpirationNotify,
  shopCashbackAddPoint,
  shopCashbackUsePoint,
  shopCashbackCancelPoint,
  classTicketExpireNotification,
  memberLevelUp,
  memberLevelDown,
  memberLevelRemain,
  memberLevelExpire,
  shopPointAddPoint,
  shopPointCancelPoint,
  shopPointExpirationNotify,
  shopPointUsePoint,
  memberSuccessReferral,
}) => {
  const res = await admin({
    method: 'put',
    url: `/${shopId}/linePushConfig`,
    data: {
      couponRecordOpen,
      couponRecordExpireNotification,
      phoneVerifyHelp,
      appointmentOpen,
      appointmentCancel,
      appointmentTomorrow,
      appointmentChangeTime,
      appointmentEnd,
      walletDeposit,
      walletUse,
      classTicketOpen,
      classTicketUse,
      cancelClassTicket,
      cancelClassTicketUse,
      allianceActivityAddQualification,
      appointmentDepositRefund,
      shopCashbackExpirationNotify,
      shopCashbackAddPoint,
      shopCashbackUsePoint,
      shopCashbackCancelPoint,
      classTicketExpireNotification,
      memberLevelUp,
      memberLevelDown,
      memberLevelRemain,
      memberLevelExpire,
      shopPointAddPoint,
      shopPointCancelPoint,
      shopPointExpirationNotify,
      shopPointUsePoint,
      memberSuccessReferral,
    },
  })

  return res.data
}

export const GetLinePushConfig = async ({ shopId }) => {
  const res = await admin({
    method: 'get',
    url: `/${shopId}/linePushConfig`,
  })

  return res.data
}

// Email Notify
export const CreateShopEmailNotify = async ({ shopId, email, config }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopEmailNotify`,
    data: {
      email,
      config,
    },
  })
}

export const UpdateShopEmailNotify = async ({ shopId, config }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopEmailNotify`,
    data: {
      config,
    },
  })
}

export const DeleteShopEmailNotify = async ({ shopId, id }) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/shopEmailNotify/${id}`,
  })
}

export const GetShopEmailNotify = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopEmailNotify`,
  })
}

export const FindShopEmailNotify = async ({ shopId, id }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopEmailNotify/${id}`,
  })
}

export const GetIndustryCategories = async () => {
  return await admin2({
    method: 'GET',
    url: '/shop/industryCategories',
  })
}
